.input-container {
  background-color: var(--bp-grayScaleWhite);
  display: inline-block;
  width: 100%;

  &__input {
    padding: 0.8rem 0.4rem;
    border: none;
    border-bottom: 1px solid var(--bp-secondary-darkGrayishBlue300);
    width: 100%;
    font-size: 40px;
    font-family: 'prelo-medium', sans-serif;
    color: var(--bp-grayScale500);
    outline: none;
    text-align: center;

    input::placeholder {
      font-size: 14px;
      color: #929296;
    }

    &:focus-visible {
      outline: none;
      border-bottom: 1px solid #929296;
    }

    &::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      appearance: textfield;
      margin: 0;
    }

    &.error-input {
      color: var(--bp-alert-error500);
      border: none;
      border-bottom: 1px solid var(--bp-alert-error500);
    }
  }

  &__label {
    padding-bottom: 8px;
  }

  &__helper {
    padding-top: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
