.others-product-skeleton {
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  border-radius: 8px;
  background: var(--banco-pichincha-secondary-dark-grayish-blue-100, #f4f6f9);
  &__title {
    max-width: 255px;
    padding-bottom: 10px;
  }
  &__name {
    max-width: 270px;
    padding-bottom: 22px;
  }
  &__card-title {
    max-width: 255px;
    padding-bottom: 10px;
  }
  &__card-name {
    max-width: 270px;
  }
}
