.recharge-page {
  height: 100%;
  max-width: 528px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  &__body {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px;
    flex: 1;

    &__amount-selector {
      padding-top: 16px;
      padding-bottom: 32px;
    }

    &__account-selector {
      display: flex;
      flex-direction: column;
      padding-bottom: 32px;
      gap: 16px;

      &__selected {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &__error {
          display: flex;
          gap: 4px;
          align-items: center;
        }
      }
    }
  }

  &__footer {
    position: sticky;
    width: 100%;
    bottom: 0;
    background-color: var(--bp-grayScaleWhite);

    &__button {
      padding: 16px 24px 24px;
      & > * {
        width: 100%;
      }
    }
  }
}
