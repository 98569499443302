.benefit {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem 0.5rem;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--bp-secondary-darkGrayishBlue100);
    padding: 0.5rem;
  }
}
