.product-information-container {
  display: flex;
  flex-direction: column;
  &__header {
    padding-bottom: 16px;
  }
  &__body{
    display: flex;
    flex-direction: column;
  }
}

.benefits-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  &__benefit {
    display: flex;
    gap: 16px;
    &__icon {
      display: flex;
      width: 24px;
      height: 24px;
      padding: 8px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 40px;
      background: var(--banco-pichincha-secondary-dark-grayish-blue-100, #f4f6f9);
    }
  }
}
