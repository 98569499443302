.onboarding {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    flex: 1;

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      padding: 3rem 1.5rem 0;

      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--bp-secondary-darkGrayishBlue100);
        border-radius: 50%;
        padding: 1.5rem;
      }
    }

    &__content {
      padding: 0 1.5rem;
    }
  }

  &__footer {
    position: sticky;
    width: 100%;
    bottom: 0;
    background-color: var(--bp-grayScaleWhite);

    &__button {
      padding: 1.5rem;
      padding-top: 0.5rem;

      & > * {
        width: 100%;
      }
    }
  }
}
