.card-container {
  display: flex;
  background-size: cover;
  background-position: center center; /* Center the background image */
  background-repeat: no-repeat;
  width: 312px;
  height: 186px;
  box-sizing: border-box;
  &:active {
    opacity: 0.5;
  }

  &:focus-visible {
    outline: none !important;
  }

  &:focus {
    outline: none !important;
  }

  &:focus-within {
    outline: none !important;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 156px;
    &__header {
      display: flex;
      justify-content: space-between;
      padding: 16px 24px 8px 24px;
      &__identification {
        display: flex;
        flex-direction: column;
        gap: 4px;
        max-width: 175px;
        overflow: hidden;
        white-space: nowrap;
      }
      &__logo {
        display: flex;
        align-items: flex-start;
        gap: 4px;
      }
    }
    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 8px 24px 16px 24px;
      &__information {
        display: flex;
        flex-direction: column;
        gap: 4px;
        &__balance {
          display: inline-flex;
          align-items: center;
          max-width: 159px;
          gap: 6px;
        }
      }
      &__button {
        display: flex;
        width: 40px;
        height: 40px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 120px;
        background: rgba(255, 255, 255, 0.2);
        box-sizing: border-box;
      }
    }
  }
}
