@keyframes slide-in-bottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.modal {
  position: fixed;
  background-color: rgba(74, 74, 80, 0.8);
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 10000;
  display: inline-block;

  &--center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__outside {
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden;
  }

  &__content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    animation: slide-in-bottom 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

    &--center {
      display: flex;
      justify-content: center;
      position: relative;
      flex-shrink: 0;
      align-items: center;
    }
  }
}
