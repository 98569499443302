.voucher-page {
  max-width: 528px;
  height: 100%;
  margin: 0 auto;
  &__body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.voucher {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 528px;
  height: 100%;
  margin: 0 auto;
  padding: 0 24px;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;

    background-color: var(--bp-secondary-darkGrayishBlue50);
    border-radius: 0px 0px 8px 8px;

    &__information {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }

  &__more-actions {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__footer {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--bp-grayScaleWhite);
    border-top: 1px solid var(--bp-secondary-darkGrayishBlue300);

    & > * {
      width: 100%;
      padding: 1rem 1.5rem 1.5rem;
    }
  }
}
