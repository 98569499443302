.confirmation-page {
  height: 100%;
  max-width: 528px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  &__body {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    padding: 24px 24px 24px 24px;
    gap: 24px;
    flex: 1;
  }
  &__footer {
    position: sticky;
    width: 100%;
    bottom: 0;
    background-color: var(--bp-grayScaleWhite);
    &__button {
      display: flex;
      flex-direction: column;
      padding: 16px 24px 24px 24px;
      gap: 16px;
      & > * {
        width: 100%;
      }
    }
  }
}
