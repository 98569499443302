.recharge-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__card-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-bottom: 16px;
  }
  &__payment-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-bottom: 32px;
  }
}
