html,
body {
  display: flex;
  flex-direction: column;
  height: var(--app-height);
}

/* hide scrollbar but allow scrolling */
body {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  overflow: -moz-scrollbars-none;
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}
@-moz-document url-prefix() {
  /* Disable scrollbar Firefox */
  html {
    scrollbar-width: none;
  }
}

#root {
  flex: 1;
  height: var(--app-height);
  position: relative;
}

#app-container {
  height: var(--app-height);
  display: flex;
  flex-direction: column;
  align-items: center;
}
#header-container {
  height: 56px;
  width: 100%;
  position: fixed;
  background-color: #fff;
  z-index: 10000;
}

#page-container {
  flex: 1;
  width: 100%;
  background-color: var(--white);
}

.navigation-header {
  width: 100%;
}
