.accounts-page {
  height: 100%;
  max-width: 528px;
  margin: 0 auto;
  &__body {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px;
    gap: 16px;
  }
}
