.security-feedback {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    padding-bottom: 24px;
  }
}

.token-timer__lottie {
  align-items: center;
  display: flex;
  height: 88px;
  justify-content: center;
  position: relative;
  width: 88px;
  margin: 0 auto;

  &__animation {
    z-index: 1000;
  }

  &-bg1 {
    align-items: center;
    background-color: var(--bp-secondary-darkGrayishBlue100);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    position: absolute;
    height: 60px;
    width: 60px;
  }

  &-bg2 {
    background-color: var(--bp-grayScaleWhite);
    border-radius: 50%;
    height: 30px;
    width: 30px;
  }
}

.soft-token {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 16px;
  margin-top: 24px;
  gap: 8px;
  &__progress-bar {
    height: 16px;
    width: 16px;

    svg {
      display: block;
    }
  }

  &__text {
    display: block;
    margin-left: 8px;
  }
}
