.home {
  max-width: 528px;
  margin: 0 auto;

  &__content {
    display: flex;
    flex-direction: column;
    padding: 24px;

    &__selector {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &__favorites {
      display: flex;
      flex-direction: column;
    }
  }

  &__footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 50%;
    right: 0;
    transform: translateX(-50%);
    background-color: var(--bp-grayScaleWhite);

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 24px;
    }
  }

  &__city-account {
    display: flex;
    padding: 0 24px;
  }
}

.modal-content {
  height: 400px;
  overflow-y: scroll;

  &__footer {
    padding: 1.5rem;
    padding-top: 1rem;

    & > * {
      width: 100%;
    }
  }
}

.card-item-slot {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background: var(--banco-pichincha-secondary-dark-grayish-blue-100, #f4f6f9);
}
