.others-page {
  height: 100%;
  max-width: 528px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  &__body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    height: 100%;
    padding: 24px;
    flex: 1;

    &__verification {
      display: flex;
      align-items: baseline;
      padding-bottom: 16px;

      &__input {
        flex: 1;
        margin-right: 1rem;
      }
    }
  }

  &__footer {
    position: sticky;
    width: 100%;
    bottom: 0;
    background-color: var(--bp-grayScaleWhite);
    &__button {
      padding: 16px 24px 24px 24px;
      & > * {
        width: 100%;
      }
    }
  }
}
