.home-skeleton {
  height: 100%;
  margin: 0 auto;
  padding-top: 24px;

  &__content {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
    &__title {
      max-width: 120px;
      padding-bottom: 24px;
    }
    &__subtitle {
      max-width: 120px;
      padding-bottom: 8px;
    }
    &__card {
      display: flex;
      flex-direction: column;
      align-items: center;
      &__info {
        display: flex;
        flex-direction: column;
        width: 321px;
        height: 156px;
        border-radius: 12px;
        background-color: var(--bp-secondary-darkGrayishBlue100);
        padding: 19px 20px 28px 20px;
        box-sizing: border-box;
        &__name {
          max-width: 202px;
          padding-bottom: 4px;
        }
        &__number {
          max-width: 144px;
          padding-bottom: 53px;
        }
        &__balance {
          max-width: 144px;
        }
      }
    }
  }
}
