.error-modal {
  background-color: var(--bp-grayScaleWhite);
  border-radius: 8px;
  width: 343px;

  &__content {
    padding: 2rem 1rem;
  }

  &__actions {
    border-top: 1px solid var(--bp-secondary-darkGrayishBlue300);
    padding: 1rem;

    & > * {
      width: 100%;
    }
  }
}
