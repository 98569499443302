.error-page-container {
  height: 100%;
  background-color: var(--bp-grayScaleWhite);
}

.error-container {
  display: flex;
  padding: 24px;
  align-items: center;
  justify-content: center;
}
.error-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-body img {
  display: block;
  width: 64px;
  height: 64px;
}

.error-title {
  margin-top: 32px;
  margin-bottom: 8px;
  text-align: center;
}

.error-subtitle {
  text-align: center;
}

.error-button-container {
  display: inline-block;
  justify-content: center;
  width: 327px;
  padding: 24px;
  position: fixed;
  bottom: 0;
  cursor: pointer;

  & > * {
    width: 100%;
  }
}
