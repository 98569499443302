.chip-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 48px;
  border: 1px solid var(--banco-pichincha-secondary-dark-grayish-blue-300, #dee3ed);
  border-radius: 4px;
  margin-right: 8px;
  background: var(--banco-pichincha-grayscale-white, #fff);
  cursor: pointer;

  &:hover {
    border: 2px solid var(--banco-pichincha-alert-information-500, #2f7abf);
    background: var(--banco-pichincha-alert-information-50, #eaf1f8);
  }

  &--checked {
    border: 1px solid var(--banco-pichincha-secondary-dark-grayish-blue-400, #d3d9e7);
    background: var(--banco-pichincha-secondary-dark-grayish-blue-100, #f4f6f9);

    &:hover {
      border: 2px solid var(--banco-pichincha-alert-information-500, #2f7abf);
    }
  }

  &--has-image {
    padding: 16px 0px;
    height: 100%;
    border-radius: 8px;
    background-color: #f4f6f9;
    border: 0px;

    &-checked {
      border: 2px solid #2f7abf;
      background-color: white;

      &:hover {
        border: 2px solid #2f7abf;
      }
    }
  }

  &__image {
    width: 48px;
    height: 32px;
    border-radius: 4px;
  }
}
